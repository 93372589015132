import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap } from 'rxjs';
import { kpiActions } from '../actions/kpi.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class KpiEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getKpi$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kpiActions.getKpi),
            mergeMap(() => {
                return this.httpService.getKpi().pipe(
                    map((kpi) => kpiActions.getKpiSuccess({ kpi })),
                    catchError(() => of(kpiActions.getKpiError())),
                );
            }),
        );
    });
}
