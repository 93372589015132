import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Kpi } from '../models';

export const kpiActions = createActionGroup({
    source: 'Kpi',
    events: {
        'Get Kpi': emptyProps(),
        'Get Kpi Success': props<{ kpi: Kpi }>(),
        'Get Kpi Error': emptyProps(),

        Clear: emptyProps(),
    },
});
