import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectItem, selectLoaded } from '../selectors/kpi.selector';
import { kpiActions } from '../actions/kpi.actions';

@Injectable({
    providedIn: 'root',
})
export class KpiFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    item$ = this.store.select(selectItem);

    getKpi(): void {
        this.store.dispatch(kpiActions.getKpi());
    }

    clear(): void {
        this.store.dispatch(kpiActions.clear());
    }
}
