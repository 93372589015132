import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { KpiDto, Kpi } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getKpi() {
        return this.httpClient
            .addUserSwitch()
            .get<ApiResponse<KpiDto>>('api/v1/kpi')
            .pipe(map((response) => new Kpi(response.data)));
    }
}
