import { createReducer, on } from '@ngrx/store';
import { kpiActions } from '../actions/kpi.actions';
import { Kpi } from '../models';

export interface KpiState {
    loaded: boolean | null;
    item: Kpi | null;
}

export const initialState: KpiState = {
    loaded: null,
    item: null,
};

export const kpiReducer = createReducer(
    initialState,
    on(kpiActions.getKpi, (state): KpiState => ({ ...state, loaded: null })),
    on(kpiActions.getKpiSuccess, (state, { kpi }): KpiState => ({ ...state, loaded: true, item: kpi })),
    on(kpiActions.getKpiError, (state): KpiState => ({ ...state, loaded: false })),
    on(kpiActions.clear, (): KpiState => ({ ...initialState })),
);
