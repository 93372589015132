import { KpiDto } from './kpi.dto';

export class Kpi {
    activePurchaseOrders: number;
    purchaseOrdersLinesBalance: number;
    overduePurchaseOrdersLines: number;
    purchaseOrdersLinesInDelivery: number;
    returnOrdersLinesBalance: number;

    constructor(dto: KpiDto) {
        this.activePurchaseOrders = dto.active_purchase_orders;
        this.purchaseOrdersLinesBalance = dto.purchase_orders_lines_balance;
        this.overduePurchaseOrdersLines = dto.overdue_purchase_orders_lines;
        this.purchaseOrdersLinesInDelivery = dto.purchase_orders_lines_in_delivery;
        this.returnOrdersLinesBalance = dto.return_orders_lines_balance;
    }
}
