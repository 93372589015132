import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { KPI_FEATURE_KEY } from './keys';
import { kpiReducer } from './reducers/kpi.reducer';
import { KpiEffects } from './effects/kpi.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(KPI_FEATURE_KEY, kpiReducer), EffectsModule.forFeature([KpiEffects])],
})
export class KpiStoreModule {}
