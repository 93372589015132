import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { KpiFacade } from '../facades/kpi.facade';

export const kpiCleanup: CanDeactivateFn<unknown> = () => {
    const kpiFacade = inject(KpiFacade);

    kpiFacade.clear();
    return true;
};
